import React, { useState, useEffect } from "react";
import greenTickImg from "../../Resources/Images/greenTickInPricing.svg";
import "./styles/Pricing.css";
import {
  EVENT_TYPES,
  PROFESSION,
  EVENT_TAG,
} from "../../Constants/EventsConstants/EventsConstants";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import { eventFormValidationSchema } from "../../Utilities/EventsUtility/Validation";
import {
  initiateEventPayment,
  placeOrderAndShowRz,
  postEventParticipantDetails,
  applyCouponCode,
  getDefaultDiscountPercentage,
} from "../Events/EventRegistrationClient";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import { useQuery } from "../../Utilities/CommonUtility/CommonUtility";
import {
  HOURS_IN_A_DAY,
  MILISECONDS_IN_A_SECOND,
  MINUTES_IN_AN_HOUR,
  SECONDS_IN_A_MINUTE,
  PPA_URL,
  DEFAULT_PARTICIPANTS,
} from "../../Constants/EventsConstants/EventsConstants";
import LinkedIn from "../../Resources/Images/LinkedIn.png";
import Whatsapp from "../../Resources/Images/Whatsapp.png";
import Twitter from "../../Resources/Images/Twitter.png";
import copyImage from "../../Resources/Images/copy.png";
import {
  WhatsappShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {Modal} from "@mui/material";
// import Modal from "@material-ui/core/Modal";
import { CircularProgress } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import studentsRegistered from "../../Resources/Images/studentsRegistered.svg";
import successTick from "../../Resources/Images/awesome_tick.png";
import closeIcon from "../../Resources/Images/close-icon.png";
import useWindowDimensions from "../../Hooks/UseWindowDimensions";
import CloseIcon from "@mui/icons-material/Close";

const Pricing = (props) => {
  const modalStyle1 = {
    position: "absolute",
    top: "10%",
    left: "10%",
    overflow: "scroll",
    height: "100%",
    display: "block",
  };

  // const classes = useStyles();
  const event_details = props.event_details;
  const eventFeatures = event_details.event_features;
  const history = useHistory();
  const { registerFormModalOpen, setRegisterFormModalOpen } =
    useGlobalContext();

  const [profession, setProfession] = React.useState(
    event_details.event_category === EVENT_TAG.LOW_LEVEL_DESIGN
      ? PROFESSION.WORKING_PROFESIONAL
      : PROFESSION.STUDENT
  );
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [organisation, setOrganisation] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [copy, setCopy] = useState(false);

  const [shareEventModalOpen, setShareEventModalOpen] = useState(false);

  const [referYourFriendsModalOpen, setReferYourFriendsModalOpen] =
    useState(false);

  const [discountPercent, setDiscountPercent] = useState(null);
  const [discountedPrice, setDiscountedPrice] = useState(null);
  const [couponCodeInvalid, setCouponCodeInvalid] = useState(false);
  const [formSubmitError, setFormSubmitError] = useState("");
  const [couponCodeApplied, setCouponCodeApplied] = useState(false);
  const [defaultDiscountPercent, setDefaultDiscountPercent] = useState();

  const [referralCode, setReferralCode] = useState("");
  const query = useQuery();
  const [orderId, setOrderId] = useState("");
  const [key, setKey] = useState("");
  const [amount, setAmount] = useState(0);
  const [currency, setCurrency] = useState(0);
  const [razorpay_payment_id, setRazorpayPaymentsId] = useState("");
  const [validationError, setValidationError] = useState("");
  const [loader, setLoader] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [userAlreadyRegistered, setUserAlreadyRegistered] = useState(false);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const { windowWidth } = useWindowDimensions();
  const EVENT_SHARE_WITH_NO_COUPON_CODE = `I’m inviting you to an exciting Event by Programming Pathshala - ${event_details.event_title} - https://events.programmingpathshala.com/Events/49/. Register quickly, there's only limited seats left!`;
  const EVENT_SHARE_WITH_REFERRAL_CODE = `I’m inviting you to an exciting Summer Course by Programming Pathshala - ${event_details.event_title}. Here’s my code ${referralCode} - using which you will get a FLAT 25% OFF, for which you can register here - https://events.programmingpathshala.com/Events/${event_details.event_id}/?coupon_code=${referralCode}. Register quickly, there's only limited seats left!`;
  const EVENT_SHARE_WITH_DEAFULT_COUPON_CODE = `I’m inviting you to an exciting Event by Programming Pathshala - ${event_details.event_title} - https://events.programmingpathshala.com/Events/49/?coupon_code=${event_details.default_coupon_code}. Register quickly, there's only limited seats left!`;
  const EVENT_SHARE_WITH_URL_COUPON_CODE = `I’m inviting you to an exciting Event by Programming Pathshala - ${
    event_details.event_title
  } - https://events.programmingpathshala.com/Events/49/?coupon_code=${query.get(
    "coupon_code"
  )}. Register quickly, there's only limited seats left!`;
  const redirectToReferYourFriendsPage = () => {
    history.push(
      `/Refer-your-friend?email=${email}&name=${name}&phone=${phone}&eventId=${event_details.event_id}`
    );
  };
  const getTime = () => {
    const time = Date.parse(event_details.event_date_time) - Date.now();

    setDays(
      Math.floor(
        time /
          (MILISECONDS_IN_A_SECOND *
            SECONDS_IN_A_MINUTE *
            MINUTES_IN_AN_HOUR *
            HOURS_IN_A_DAY)
      )
    );
    setHours(
      Math.floor(
        (time /
          (MILISECONDS_IN_A_SECOND *
            SECONDS_IN_A_MINUTE *
            MINUTES_IN_AN_HOUR)) %
          HOURS_IN_A_DAY
      )
    );
    setMinutes(
      Math.floor(
        (time / MILISECONDS_IN_A_SECOND / MINUTES_IN_AN_HOUR) %
          MINUTES_IN_AN_HOUR
      )
    );
    setSeconds(
      Math.floor((time / MILISECONDS_IN_A_SECOND) % SECONDS_IN_A_MINUTE)
    );
  };

  const handleChange = (event) => {
    setProfession(event.target.value);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value.trim());
  };

  const handlePhoneChange = (e) => {
    setPhone(e);
  };

  const handleOrgChange = (e) => {
    setOrganisation(e.target.value);
  };

  const handleCouponCodeChange = (e) => {
    setCouponCode(e.target.value);
  };

  const handleCouponCodeSubmit = () => {
    setCouponCodeApplied(true);
    setLoader(true);
    applyCouponCode(
      couponCode,
      event_details.event_id,
      event_details.event_price,
      setDiscountPercent,
      setDiscountedPrice,
      setCouponCodeInvalid,
      setLoader
    );
  };

  const setPaymentDetails = (
    orderId,
    key,
    amount,
    currency,
    razorpay_payment_id
  ) => {
    setOrderId(orderId);
    setKey(key);
    setAmount(amount);
    setCurrency(currency);
    setRazorpayPaymentsId(razorpay_payment_id);
  };

  const setPaymentsId = async (razorpay_payment_id) => {
    await swal("Done", "Payment Successful.", "success");
    setReferYourFriendsModalOpen(true);
    setRazorpayPaymentsId(razorpay_payment_id);
  };

  const handleSubmitForm = () => {
    eventFormValidationSchema
      .validate({
        name: name,
        email: email,
        phone: phone,
      })
      .then(() => {
        setValidationError("");
        setFormSubmitError("");
        setLoader(true);
        let price = props.event_details.event_price;
        if (!couponCodeInvalid) {
          price = discountedPrice;
        }
        let data = {
          event_id: props.event_details.event_id,
          name: name,
          email: email,
          phone_number: phone,
          organisation: organisation,
          profession: profession,
          couponCode: couponCode,
          price: price,
          affiliate_id: localStorage.getItem("affiliate_id"),
        };
        if (event_details.event_type === EVENT_TYPES.PAID) {
          initiateEventPayment(
            data,
            setPaymentDetails,
            placeOrderAndShowRz,
            setPaymentsId,
            email,
            setLoader,
            redirectToReferYourFriendsPage,
            setFormSubmitError,
            setReferralCode,
            setRegisterFormModalOpen
          );
        } else {
          postEventParticipantDetails(
            data,
            setLoader,
            setUserAlreadyRegistered,
            setRegistrationSuccess,
            setFormSubmitError
          );
        }
      })
      .catch((err) => {
        setValidationError(err.errors);
      });
  };

  const closeSuccessModal = () => {
    setReferYourFriendsModalOpen(false);
  };

  const getSuccessModal = () => {
    return (
      <Modal
        open={referYourFriendsModalOpen}
        onClose={() => closeSuccessModal()}
      >
        <section className="success-modal-wrapper-bg">
          <div className="success-modal-wrapper">
            <CloseIcon
              className="success-modal-close-icon"
              onClick={() => closeSuccessModal()}
            />
            <img className="success-tick" src={successTick} alt="" />
            <span className="success-modal-heading">Awesome</span>
            <p className="success-modal-message">
              Your registration has been successful. Please check your Email
              Inbox and WhatsApp for the event details.
            </p>
            <p className="success-modal-message">
              Want to win exciting gifts like <strong>boAt Earphones?</strong>{" "}
              Share your Referral Code <strong>"{referralCode}"</strong> with
              your friends, and win exclusive goodies! 🎁
            </p>
            <div
              className="success-modal-button"
              onClick={redirectToReferYourFriendsPage}
            >
              Check what Goodies you can win!
            </div>
            <h3 className="share-word">Spread the word</h3>
            <div className="share-icons-wrapper">
              <img
                className="share-icon-img"
                src={copyImage}
                onClick={handleCopyLink}
              />
              <LinkedinShareButton
                title={EVENT_SHARE_WITH_REFERRAL_CODE}
                url={PPA_URL}
              >
                <img className="share-icon-img" src={LinkedIn} />
              </LinkedinShareButton>
              <TwitterShareButton
                title={EVENT_SHARE_WITH_REFERRAL_CODE}
                url={PPA_URL}
              >
                <img className="share-icon-img" src={Twitter} />
              </TwitterShareButton>
              <WhatsappShareButton
                title={EVENT_SHARE_WITH_REFERRAL_CODE}
                url={PPA_URL}
              >
                <img className="share-icon-img" src={Whatsapp} />
              </WhatsappShareButton>
            </div>
          </div>
        </section>
      </Modal>
    );
  };

  const handleShareEventModal = (e) => {
    e.preventDefault();
    setShareEventModalOpen(true);
  };

  const closeShareEventModal = () => {
    setShareEventModalOpen(false);
    setRegistrationSuccess(false);
  };
  const handleCopyLink = () => {
    setCopy(!copy);
    let eventLink = "";
    if (referralCode) {
      eventLink = `${process.env.REACT_APP_EVENTS_FRONTEND_URL}Events/${event_details.event_id}?coupon_code=${referralCode}`;
    } else if (query.get("coupon_code")) {
      eventLink = `${process.env.REACT_APP_EVENTS_FRONTEND_URL}Events/${
        event_details.event_id
      }?coupon_code=${query.get("coupon_code")}`;
    } else if (event_details.default_coupon_code) {
      eventLink = `${process.env.REACT_APP_EVENTS_FRONTEND_URL}Events/${event_details.event_id}?coupon_code=${event_details.default_coupon_code}`;
    } else {
      eventLink = `${process.env.REACT_APP_EVENTS_FRONTEND_URL}Events/${event_details.event_id}`;
    }
    navigator.clipboard.writeText(eventLink);
  };

  const getEventShareMessage = () => {
    let msgContent = EVENT_SHARE_WITH_NO_COUPON_CODE;
    if (event_details.event_type === EVENT_TYPES.PAID) {
      if (referralCode) {
        //user trying to share paid event after registering for it.
        msgContent = EVENT_SHARE_WITH_REFERRAL_CODE;
      } else if (query.get("coupon_code")) {
        //user sharing event before registering with coupon code from url.
        msgContent = EVENT_SHARE_WITH_URL_COUPON_CODE;
      } else if (event_details.default_coupon_code) {
        //user sharing event before registering with default coupon code of event
        msgContent = EVENT_SHARE_WITH_DEAFULT_COUPON_CODE;
      }
    }
    return msgContent;
  };

  const getShareEventModal = () => {
    return (
      <Modal
        open={shareEventModalOpen || registrationSuccess}
        onClose={() => closeShareEventModal()}
      >
        <section className="share-modal-wrapper-bg">
          <div className="share-modal-wrapper">
            <CloseIcon
              className="success-modal-close-icon"
              onClick={() => closeShareEventModal()}
            />
            <h3 className="share-modal-heading">
              {registrationSuccess
                ? "You have successfully registered. Please check your email for the Joining link"
                : "Spread the word :)"}{" "}
            </h3>

            <div className="share-icons-wrapper">
              <img
                className="share-icon-img"
                src={copyImage}
                onClick={handleCopyLink}
              />
              <LinkedinShareButton title={getEventShareMessage()} url={PPA_URL}>
                <img className="share-icon-img" src={LinkedIn} />
              </LinkedinShareButton>
              <TwitterShareButton title={getEventShareMessage()} url={PPA_URL}>
                <img className="share-icon-img" src={Twitter} />
              </TwitterShareButton>
              <WhatsappShareButton title={getEventShareMessage()} url={PPA_URL}>
                <img className="share-icon-img" src={Whatsapp} />
              </WhatsappShareButton>
            </div>
            {copy && (
              <p className="event-link-copied-msg">The event link is copied</p>
            )}
          </div>
        </section>
      </Modal>
    );
  };

  const scrollToRegForm = () => {
    if (windowWidth > 600) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    } else {
      window.scrollTo({ top: 450, left: 0, behavior: "smooth" });
    }
    setRegisterFormModalOpen(true);
  };

  const getPricingSection = () => {
    if (discountPercent)
      return (
        <>
          <div className="event-amount-wrapper">
            <p className="discounted-amount-pricing">Rs {discountedPrice}/-</p>
            <p className="event-actual-price">
              Rs {event_details.event_price}/-
            </p>
            <p className="event-discount-percent">{discountPercent} % OFF </p>
          </div>
        </>
      );
    else
      return (
        <div>
          <p className="discounted-amount-pricing">
            Rs {event_details.event_price}/-
          </p>
        </div>
      );
  };

  const closeRegisterFormModal = () => {
    setRegisterFormModalOpen(false);
  };

  const getRegisterFormModal = () => {
    return (
      <Modal
        open={registerFormModalOpen}
        onClose={() => closeRegisterFormModal()}
        sx={modalStyle1}
      >
        <div className="reg-form-modal-wrapper-bg">
          <div className="reg-form-modal-wrapper">
            <form className="event-register-form" action="">
              <div className="reg-now-close-icon-wrapper">
                <p className="event-register-form-heading">Register Now!</p>
                <div onClick={() => closeRegisterFormModal()}>
                  <img className="close-icon" src={closeIcon} alt="" />
                </div>
              </div>

              <label>
                <input
                  placeholder="Name"
                  type="text"
                  onChange={handleNameChange}
                />
              </label>
              <label>
                <input
                  placeholder="Email"
                  type="text"
                  onChange={handleEmailChange}
                />
              </label>
              <label>
                <PhoneInput
                  placeholder="Enter phone number"
                  inputStyle={{
                    background: "#FFFFFF",
                    width: "100%",
                    height: "46px",
                  }}
                  buttonStyle={{
                    background: "#FFFFFF",
                    padding: "2px",
                  }}
                  country={"in"}
                  value={phone}
                  onChange={(phone) => handlePhoneChange(phone)}
                />
              </label>
              <label>
                <input
                  placeholder="Organisation/College Name"
                  type="text"
                  onChange={handleOrgChange}
                />
              </label>

              <FormControl sx={{ minWidth: 120 }}>
                <Select
                  value={profession}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    color: "#191d23",
                    height: "46px",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#D0D5DD",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#D0D5DD",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#D0D5DD",
                    },
                    ".MuiSvgIcon-root ": {
                      fill: "#191d23 !important",
                    },
                  }}
                >
                  <MenuItem value="Working Professional">
                    Working Professional
                  </MenuItem>
                  <MenuItem value="Student">Student</MenuItem>
                </Select>
              </FormControl>
              {validationError && (
                <div className="error-text">
                  <p>{validationError}</p>
                </div>
              )}
              {userAlreadyRegistered && (
                <div className="user-already-registered">
                  The user is already registered.
                </div>
              )}

              {formSubmitError && (
                <div className="error-text">
                  <p>{formSubmitError}</p>
                </div>
              )}

              {event_details.event_type === EVENT_TYPES.FREE ? (
                <div
                  className="register-or-pay-btn-wrapper"
                  onClick={() => handleSubmitForm()}
                >
                  Register Now
                </div>
              ) : (
                <>
                  {getPricingSection()}
                  {getCouponCodeDetails("modal-form")}
                  <div
                    className="register-or-pay-btn-wrapper"
                    onClick={() => handleSubmitForm()}
                  >
                    Pay Now
                  </div>
                </>
              )}

              {loader && (
                <div className="register-form-loader">
                  <CircularProgress size={30} />
                </div>
              )}

              <div className="form-share-and-referrel-btn-wrapper">
                <button
                  className="form-share-btn"
                  onClick={handleShareEventModal}
                >
                  Share
                </button>
              </div>

              {getShareEventModal()}
            </form>
          </div>
        </div>
      </Modal>
    );
  };

  useEffect(() => {
    if (event_details.default_coupon_code) {
      getDefaultDiscountPercentage(
        event_details.default_coupon_code,
        event_details.event_id,
        setDefaultDiscountPercent
      );
      setCouponCode(event_details.default_coupon_code);
    }
    if (query.get("coupon_code")) {
      setCouponCode(query.get("coupon_code"));
      applyCouponCode(
        query.get("coupon_code"),
        event_details.event_id,
        event_details.event_price,
        setDiscountPercent,
        setDiscountedPrice,
        setCouponCodeInvalid,
        setLoader
      );
    }
  }, []);

  const time = (value, time_scale) => {
    let formattedNumber = value;
    if (value >= 0 && value <= 9) {
      formattedNumber = ("0" + value).slice(-2);
    }

    return (
      <>
        <div className="poster-countdown">
          <p className="time-and-date-value">
            {formattedNumber}
            {"    "}
            {time_scale !== "Seconds" ? (
              <span className="time-and-date-value">:</span>
            ) : (
              ``
            )}
          </p>
          <p className="time-and-date-name">{time_scale}</p>
        </div>
      </>
    );
  };

  const getCouponCodeDetails = (formName) => {
    return (
      <>
        {event_details.event_type === EVENT_TYPES.PAID && (
          <div className={`${formName}-event-apply-coupon-input`}>
            <input
              type="text"
              value={couponCode}
              onChange={handleCouponCodeChange}
            />
            <div
              className={`${formName}-event-apply-coupon-btn`}
              onClick={() => handleCouponCodeSubmit()}
            >
              Apply
            </div>
            <div className="event-applied-coupon-text">
              {couponCodeApplied ? "Applied" : ""}
            </div>
            <div className="event-coupon-error-text">
              {couponCodeInvalid && <p>Invalid coupon code</p>}
            </div>
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    const interval = setInterval(() => getTime(), 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="pricing-wrapper">
      <h3 className="time-span-title">
        Time Is Running Out.{" "}
        <span className="grab-your-spot-title ">Grab Your Spot Fast!</span>
      </h3>

      <div className="pricing-card">
        <div className="time-span-box">
          {time(days, "Days")}
          {time(hours, "Hours")}
          {time(minutes, "Minutes")}
          {time(seconds, "Seconds")}
        </div>
        {event_details.event_type === EVENT_TYPES.PAID && (
          <div className="pricing-heading">PRICING</div>
        )}

        <div className="event-topic-for-pricing">
          {event_details.event_title}
        </div>
        <div className="event-features-heading">Features</div>
        <div className="event-features-points">
          {eventFeatures &&
            eventFeatures.map((event_feature) => (
              <div className="event-features">
                <img src={greenTickImg} />
                <span>{event_feature}</span>
              </div>
            ))}
        </div>
        {event_details.event_type === EVENT_TYPES.PAID && getPricingSection()}

        {getCouponCodeDetails("form")}

        <div className="register-button-and-detail">
          <button
            className="time-span-register-now-cta"
            onClick={() => scrollToRegForm()}
          >
            Register Now
          </button>
          {/* {event_details.event_type === "paid" && (
              <p className="pricing-referral-cashback">
                Refer a friend and get{" "}
                <span className="cashback-amount">₹1000 cashback!</span>
              </p>
            )} */}

          <div className="pricing-enrolled-people">
            <img alt="" src={studentsRegistered} />
            <span className="enrolled-people-text">
              {event_details.total_participants
                ? event_details.total_participants
                : DEFAULT_PARTICIPANTS}
              + Students Already Registered
            </span>
          </div>
          {getRegisterFormModal()}
        </div>
        {referYourFriendsModalOpen && getSuccessModal()}
      </div>
    </div>
  );
};

export default Pricing;
