import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./styles/ReferYourFriendBanner.css";
import giftBox from "../../../Resources/Images/giftBox.svg";
import ReferFriendBanner from "../../../Resources/Images/ReferFriendBanner.svg";
import { useEffect, useState } from "react";
import {
    getReferralCode,
    generateReferralCode,
    getUserPoints,
    getLeaderBoard,
} from "../ReferYourFriendsClient";
import { Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { isNULLEMPTYORUNDEFINED } from "../../../Utilities/CommonUtility/CommonUtility";
import EarnCoins from "../EarnCoins/EarnCoins";
import HowDoesItWork from "../HowDoesItWork/HowDoesItWork";
import LeaderBoard from "../LeaderBoard/LeaderBoard";
import PhoneInput from "react-phone-input-2";
import {
    generateFormValidationSchema,
    viewFormValidationSchema,
} from "../../../Utilities/EventsUtility/Validation";
import { CircularProgress } from "@mui/material";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};
const ReferYourFriendBanner = () => {
    const query = useQuery();
    const history = useHistory();
    const [referralCode, setReferralCode] = useState("");

    const [mode, setMode] = useState("");
    const [viewGenerateModalOpen, setViewGenerateModalOpen] = useState(false);
    const [viewGenerateSuccessModalOpen, setViewGenerateSuccessModalOpen] = useState(false);
    const [viewGenerateSuccessMsg, setViewGenerateSuccessMsg] = useState("");
    const [userPoints, setUserPoints] = useState("");
    const [leaderboard, setLeaderBoard] = useState([]);

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [validationError, setValidationError] = useState("");
    const [loader, setLoader] = useState(false);

    const [copy, setCopy] = useState(false);

    useEffect(() => {
        if (isNULLEMPTYORUNDEFINED(query.get("eventId"))) {
            history.push("/Events");
        }

        if (query.get("email") && query.get("name") && query.get("phone")) {
            setEmail(query.get("email"));
            let data = {
                email: query.get("email"),
                name: query.get("name"),
                phone: query.get("phone"),
                event_id:query.get("eventId")
            };
            generateReferralCode(
                data,
                setReferralCode,
                setLoader,
                setViewGenerateSuccessMsg,
                setViewGenerateSuccessModalOpen
            );
        }
    }, []);

    useEffect(() => {
        if (!isNULLEMPTYORUNDEFINED(referralCode)) {
            getUserPoints(query.get("eventId"), referralCode, setUserPoints);
            getLeaderBoard(query.get("eventId"), setLeaderBoard);
        }
    }, [referralCode]);

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };
    const handlePhoneChange = (e) => {
        setPhone(e);
    };
    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleViewGenerateModal = (mode) => {
        setMode(mode);
        setViewGenerateModalOpen(true);
    };
    const closeViewGenerateModal = () => {
        setViewGenerateModalOpen(false);
    };

    const handleViewGenerateFormSubmit = (e) => {
        e.preventDefault();
        if (mode === "view") {
            viewFormValidationSchema
                .validate({
                    email: email,
                })
                .then(() => {
                    setValidationError("");
                    setLoader(true);
                    setViewGenerateModalOpen(false);

                    getReferralCode(
                        email,
                        query.get("eventId"),
                        setReferralCode,
                        setLoader,
                        setViewGenerateSuccessMsg,
                        setViewGenerateSuccessModalOpen
                    );
                })
                .catch((err) => {
                    setValidationError(err.errors);
                });
        } else if (mode === "generate") {
            generateFormValidationSchema
                .validate({
                    name: name,
                    email: email,
                    phone: phone,
                })
                .then(() => {
                    setValidationError("");
                    setLoader(true);
                    setViewGenerateModalOpen(false);

                    let data = {
                        email: email,
                        name: name,
                        phone: phone,
                        event_id:query.get("eventId")
                    };
                    generateReferralCode(
                        data,
                        setReferralCode,
                        setLoader,
                        setViewGenerateSuccessMsg,
                        setViewGenerateSuccessModalOpen
                    );
                })
                .catch((err) => {
                    setValidationError(err.errors);
                });
        }
    };
    const viewGenerateModal = () => {
        return (
            <Modal open={viewGenerateModalOpen} onClose={() => closeViewGenerateModal()}>
                <section className="generate-referral-modal-wrapper-bg">
                    <div className="generate-referral-modal-wrapper">
                        <CloseIcon
                            className="generate-referral-modal-close-icon"
                            onClick={() => closeViewGenerateModal()}
                        />
                        <span className="generate-referral-modal-heading">Enter Details</span>
                        <form
                            className="generate-referral-modal-form"
                            onSubmit={handleViewGenerateFormSubmit}
                        >
                            {mode === "generate" && (
                                <label>
                                    <input
                                        placeholder="Name"
                                        type="text"
                                        onChange={handleNameChange}
                                    />
                                </label>
                            )}

                            <label>
                                <input
                                    placeholder="Email"
                                    type="text"
                                    onChange={handleEmailChange}
                                />
                            </label>
                            {mode === "generate" && (
                                <label>
                                    <PhoneInput
                                        inputStyle={{
                                            background: "#fffff",
                                        }}
                                        country={"in"}
                                        value={phone}
                                        placeholder="Whatsapp Number"
                                        onChange={(phone) => handlePhoneChange(phone)}
                                    />
                                </label>
                            )}

                            <div className="error-text">
                                {validationError && <p>{validationError}</p>}
                            </div>

                            <button>Get Referral Code</button>
                        </form>
                    </div>
                </section>
            </Modal>
        );
    };

    const closeViewGenerateSuccessModal = () => {
        setViewGenerateSuccessModalOpen(false);
    };

    const handleCopyEventLink = () => {
        setCopy(!copy);
        let eventLink;
        if (query.get("eventId")) {
            eventLink = `${process.env.REACT_APP_EVENTS_FRONTEND_URL}Events/${query.get(
                "eventId"
            )}?coupon_code=${referralCode}`;
        } else {
            eventLink = `${process.env.REACT_APP_EVENTS_FRONTEND_URL}Events`;
        }
        navigator.clipboard.writeText(eventLink);
    };
    const bannerContents = () => {
        return (
            <div className="refer-your-friend-intro-contents-wrapper">
                <h3 className="black-banner-title   refer-banner-common-title ">
                    Invite friends to Events,
                </h3>

                <div className="coloured-banner-title refer-banner-common-title">
                    {" "}
                    Collect Points and Win Exciting Gifts
                    <div className="gift-box">
                        <img src={giftBox} alt="" />
                    </div>
                </div>

                <p className="refer-banner-primiary-desc">
                    We're giving away some amaaazinggg prizes and goodies like{" "}
                    <strong>boAt Earphones, Keyboard, Discount on courses,</strong> and much more 🤩
                </p>
                <div className="gift-items-display-wrapper">Worth a total of INR 5000/-</div>
                <p className="refer-banner-secondary-desc">
                    So what are you waiting for? Spread the word to win these incredible goodies
                    now!
                </p>
                {referralCode ? (
                    <div>
                        <p className="referal-code-content">Your Referral Code: {referralCode}</p>
                        <div className="referral-button-wrapper">
                            <button className="copy-button" onClick={handleCopyEventLink}>
                                {copy ? "Copied" : "Copy Sharable Link"}
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="generate-view-referral-code">
                        <button
                            className="refer-banner-referral-button"
                            onClick={() => handleViewGenerateModal("generate")}
                        >
                            Generate Referral Code
                        </button>
                        {viewGenerateModal()}
                        <p
                            className="view-referal-code"
                            onClick={() => handleViewGenerateModal("view")}
                        >
                            View Referral Status
                        </p>
                    </div>
                )}
                {loader && (
                    <div className="view-gen-form-loader">
                        <CircularProgress size={50} />
                    </div>
                )}
            </div>
        );
    };

    const getReferFriendBannerImg = () => {
        return (
            <div>
                <img className="refer-banner-intro-img" src={ReferFriendBanner} alt="" srcset="" />
            </div>
        );
    };

    return (
        <>
            <div className="refer-your-friend-banner-wrapper">
                {bannerContents()}
                {getReferFriendBannerImg()}
            </div>
            <EarnCoins points={userPoints} />
            <HowDoesItWork />
            {leaderboard && leaderboard.length > 0 && (
                <LeaderBoard data={leaderboard} user={email} />
            )}
        </>
    );
};

export default ReferYourFriendBanner;
