import React from "react";
import { useState } from "react";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import { leadsFormValidationSchema } from "../../Utilities/EventsUtility/Validation";
import postEventLeads from "./Curriculum/CurriculumClient";
import { Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PhoneInput from "react-phone-input-2";
import { sendEventToGTM } from "../../Utilities/GoogleAnalytics/gtmManager";
import "react-phone-input-2/lib/style.css";
import "./styles/Events.css";

const EventLeads = (props) => {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [validationError, setValidationError] = useState("");
    const [loader, setLoader] = useState(false);
    const { leadsFormModalOpen, setLeadsFormModalOpen } = useGlobalContext();

    const closeLeadsFormModal = () => {
        setLeadsFormModalOpen(false);
    };

    const handlePhoneChange = (e) => {
        setPhone(e);
    };
    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleLeadsFormSubmit = (e) => {
        e.preventDefault();
        sendEventToGTM({ event: "event-curriculum-downloaded" });
        leadsFormValidationSchema
            .validate({
                name: name,
                phone: phone,
            })
            .then(() => {
                setValidationError("");
                setLoader(true);
                postEventLeads(
                    name,
                    phone,
                    "events",
                    props.event_details.event_id,
                    props.event_details.event_curriculum_pdf,
                    setLoader,
                    setValidationError
                );
            })
            .catch((err) => {
                setValidationError(err.errors);
            });
    };

    return (
        <Modal open={leadsFormModalOpen} onClose={() => closeLeadsFormModal()}>
            <section className="event-leads-modal-wrapper-bg">
                <div className="event-leads-modal-wrapper">
                    <CloseIcon
                        className="event-leads-modal-close-icon"
                        onClick={() => closeLeadsFormModal()}
                    />
                    <span className="event-leads-modal-heading">Enter Details</span>
                    <form
                        className="event-leads-modal-form"
                        onSubmit={(e) => handleLeadsFormSubmit(e)}
                    >
                        <label>
                            <input placeholder="Name" type="text" onChange={handleNameChange} />
                        </label>

                        <label>
                            <PhoneInput
                                buttonStyle={{ width: "45px" }}
                                inputStyle={{
                                    background: "#f9f8f8",
                                    width: "100%",
                                    border: "1px solid #e3e3e3",
                                    height: "45px",
                                }}
                                country={"in"}
                                value={phone}
                                placeholder="Whatsapp Number"
                                onChange={(phone) => handlePhoneChange(phone)}
                            />
                        </label>

                        <div className="error-text">
                            {validationError && <p>{validationError}</p>}
                        </div>

                        <button>Get Curriculum</button>
                    </form>
                </div>
            </section>
        </Modal>
    );
};

export default EventLeads;
